import "bootstrap/dist/css/bootstrap.min.css"
import "./node_modules/sweetalert2/dist/sweetalert2.all.min.js"
import "bootstrap/dist/js/bootstrap.min.js"
import 'jquery/dist/jquery.min'
import "bootstrap/dist/css/bootstrap-grid.css"
import "bootstrap/dist/css/bootstrap-reboot.css"
import 'swiper/swiper.scss'
import 'swiper/swiper.esm'
import 'swiper/swiper-react.esm'
import 'swiper/esm/components/effect-coverflow/effect-coverflow'
import 'swiper/swiper-bundle.css'
import 'swiper/swiper-bundle'
import 'swiper/esm/swiper-react'
import 'swiper/cjs/swiper-react'
